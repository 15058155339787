$black: #333333;
$white: #ffffff;
$white2: #e2e8f0;
$salmon: #fa8072;
$lightBlue: #4D96FF;
$darkBlue: #446df6;
$lightGreen: #aceca1;
$darkGreen: #6BCB77;
$lightGray: #efefef;
$veryDarkGray: #999999;
$lightViolet: #ac87fe;
$darkYellow: #FFD93D;
$lightYellow: #fbf34c;
$lightRed: #FF6B6B;
$bodyPrimary: #c2c2c2;
$textHeadingPrimary: #add8e6;
$secondary: #4a5568;
$primary: #2d3748;

 $colors: ("black": #333333,
"white": #ffffff,
"white2": #e2e8f0,
"salmon": #fa8072,
"lightBlue": #4D96FF,
"darkBlue": #446df6,
"lightGreen": #aceca1,
"darkGreen": #6BCB77,
"lightGray": #efefef,
"veryDarkGray": #999999,
"lightViolet": #ac87fe,
"darkYellow": #FFD93D,
"lightYellow": #fbf34c,
"lightRed": #FF6B6B,
"bodyPrimary": #c2c2c2,
"textHeadingPrimary": #add8e6,
"secondary": #4a5568,
"primary": #2d3748,
)