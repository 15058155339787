@import "./colors";
@import "./breakpoints";

.m-left-auto {
  margin-left: auto;
}

.m-right-auto {
  margin-right: auto;
}

.mv-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.m-auto {
  margin: auto;
}

.flex {
  display: flex;
}

.block {
  display: block;
}

.inline-flex {
  display: inline-flex;
}

.column {
  flex-direction: column;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.h-100vh {
  height: 100vh;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.wrap {
  flex-wrap: wrap;
}

.center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin ui-classes($name, $property, $valueIn, $inc) {
  $r: 40;

  @if ($valueIn==pct) {
    $valueIn: 0%;
    $r: 10;
  }

  @for $i from 1 through $r {
    .#{$name}-#{$i} {
      #{$property}: #{$i * $inc} + #{$valueIn};
    }
  }
}

// //padding
@include ui-classes("p", "padding", px, 4);
@include ui-classes("p-top", "padding-top", px, 4);
@include ui-classes("p-bottom", "padding-bottom", px, 4);
@include ui-classes("p-right", "padding-right", px, 4);
@include ui-classes("p-left", "padding-left", px, 4);

// //margin
@include ui-classes("m", "margin", px, 4);
@include ui-classes("m-top", "margin-top", px, 4);
@include ui-classes("m-bottom", "margin-bottom", px, 4);
@include ui-classes("m-right", "margin-right", px, 4);
@include ui-classes("m-left", "margin-left", px, 4);

//width
@include ui-classes("width-pct", "width", pct, 1);
@include ui-classes("width-px", "width", px, 10);

// height
@include ui-classes("height-pct", "height", pct, 1);
@include ui-classes("height-px", "height", px, 10);

//gap
@include ui-classes("gap", "gap", px, 4);
