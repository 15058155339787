@import './common';
@import './loco-scroll';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;

  &::selection {
    background-color: $primary;
    color: #ffffff;
  }

}

body {
  background: #141821;
}


@media (max-width: 720px) {
  html {
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  html {
    font-size: 9px;
  }
}

@media (max-width: 300px) {
  html {
    font-size: 6px;
  }
}